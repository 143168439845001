import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const PortfolioTab = ({ toggleModal, portfolioList }: { toggleModal: (event?: React.MouseEvent<HTMLButtonElement>, portfolio?: any) => void; portfolioList: any[] }) => {
  return (
    <Card>
      <CardHeader className="border-1 align-items-center d-flex">
        <h1 className="card-title mb-0 flex-grow-1">Lista de Portfólio</h1>
        <div className="d-flex gap-1">
          <Button color="success" size="sm" onClick={toggleModal}>
            <i className="ri-add-line align-middle me-1"></i> Novo Portfólio
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          {portfolioList.map((item: any, key: number) => (
            <Col xxl={3} sm={6} key={key}>
              <Card className="profile-project-card shadow-none profile-project-warning">
                <CardBody className="p-4">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <p className="text-muted text-truncate mb-1">
                        Nome: <span className="fw-semibold text-body">{item.name}</span>
                      </p>
                      <p className="text-muted text-truncate mb-1">
                        Descrição: <span className="fw-semibold text-body">{item.descricao}</span>
                      </p>
                      <p className="text-muted text-truncate mb-1">
                        Número da Conta: <span className="fw-semibold text-body">{item.login_id}</span>
                      </p>
                      <div className="mb-1">
                        Magics:
                        <div>
                          {item.portfolioMagics.map((magic: any) => (
                            <span key={magic.id} className="badge bg-primary text-white-50 ms-1">
                              {magic.magic}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="form-check form-switch align-self-start">
                      <div className="flex-shrink-0">
                        <Button color="link" onClick={(e) => toggleModal(e, item)} className="p-0 border-0 bg-transparent">
                          <i className="ri-pencil-fill align-bottom me-1"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default PortfolioTab;
