import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Section from './Section';
import Widgets from './Widgets';
import { useDispatch } from 'react-redux';
import { getMonteCarloByMagicNumber } from 'slices/thunks';
import MonteCarloChart from './MonteCarloChart';
import CountUp from 'react-countup';

const MonteCarloDetalhes = () => {
  document.title = 'Simulação Monte Carlo';

  const dispatch: any = useDispatch();
  const [monteCarloData, setMonteCarloData] = useState<any>(null);
  const [chartColors, setChartColors] = useState<string[]>([]);

  // Extrai o magicNumber da URL e converte para número
  const { magicNumber } = useParams();
  const magicNumberNumeric = Number(magicNumber);

  useEffect(() => {
    const fetchMonteCarloData = async () => {
      try {
        const response = await dispatch(getMonteCarloByMagicNumber({ magic_number: magicNumberNumeric })).unwrap();
        setMonteCarloData(response);

        // Gerar cores para o gráfico
        const numberOfSimulations = 100;
        const newChartColors = Array.from({ length: numberOfSimulations }, generateRandomColor);
        setChartColors(newChartColors);
      } catch (error) {
        console.error('Erro ao buscar dados de Monte Carlo:', error);
      }
    };

    fetchMonteCarloData();
  }, [dispatch, magicNumberNumeric]);

  const generateRandomColor = () => {
    const colorNumber = Math.floor(Math.random() * 16777215);
    const colorHex = colorNumber.toString(16).padStart(6, '0');
    return '#' + colorHex;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section />
                <Row className="mb-3">
                  <Card>
                    <CardHeader className="border-0 align-items-center d-flex">
                      <h1 className="card-title mb-0 flex-grow-1">
                        {' '}
                        Simulacao de monte carlo <span style={{ fontSize: '0.75em' }}>(Gráfico meramente ilustrativo)</span>
                      </h1>
                    </CardHeader>
                    {monteCarloData && (
                      <CardHeader className="p-0 border-0 bg-light-subtle">
                        <Row className="g-0 text-center">
                          <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0">
                              <h5 className={`mb-1 text-success`}>
                                <CountUp start={0} end={1000} duration={3} decimals={2} prefix="R$" separator="," />
                              </h5>
                              <p className="text-muted mb-0">Lucro total</p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0">
                              <h5 className="mb-1 text-warning">
                                <CountUp start={0} decimals={2} end={monteCarloData.min_drawdown} duration={3} prefix="R$" />
                              </h5>
                              <p className="text-muted mb-0">Mínimo Drawndown</p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0">
                              <h5 className="mb-1 text-warning">
                                <CountUp prefix="R$" start={0} end={monteCarloData.medio_drawdown} duration={3} decimals={2} />
                              </h5>
                              <p className="text-muted mb-0">Drawndown Médio</p>
                            </div>
                          </Col>
                          <Col xs={6} sm={3}>
                            <div className="p-3 border border-dashed border-start-0 border-end-0">
                              <h5 className={`mb-1 text-danger`}>
                                <CountUp start={0} end={monteCarloData.max_drawdown} decimals={2} duration={3} prefix="R$" />
                              </h5>
                              <p className="text-muted mb-0">Máximo Drawndown</p>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                    )}
                    <CardBody className="p-0 pb-2">
                      <div className="w-100">
                        <div dir="ltr">
                          <MonteCarloChart numSimulations={100} numDataPoints={200} dataColors={JSON.stringify(chartColors)} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                <Row>
                  {monteCarloData && (
                    <Widgets
                      desvioPadraoDrawdown={monteCarloData.desvio_padrao_drawdown}
                      medioDrawdown={monteCarloData.medio_drawdown}
                      doisDesvioPadraoDrawdown={monteCarloData.dois_desvio_padrao_drawdown}
                      tresDesvioPadraoDrawdown={monteCarloData.tres_desvio_padrao_drawdown}
                    />
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MonteCarloDetalhes;
