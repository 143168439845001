import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);

  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu');
      const iconItems: any = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        var id = item.getAttribute('sub-items');
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
  }, [history, iscurrentState, isDashboard]);

  const menuItems: any = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState('Dashboard');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'charts',
          label: 'Charts',
          link: '/dashboard',
          parentId: 'dashboard',
        },
      ],
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'ri-settings-line',
      link: '/settings',
    },
    {
      id: 'monteCarlo',
      label: 'Monte Carlo',
      icon: 'ri-bar-chart-line',
      link: '/monte-carlo',
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
