import { createSlice } from '@reduxjs/toolkit';
import { getCapitalCurveDataByPortfolioIdData } from './thunk';
export const initialState: any = {
  historyPortfolioCurveData: [],
  error: {},
};
const DashboardPortfolioSlice = createSlice({
  name: 'DashboardPortfolio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCapitalCurveDataByPortfolioIdData.fulfilled, (state, action) => {
      state.historyPortfolioCurveData = action.payload;
    });
    builder.addCase(getCapitalCurveDataByPortfolioIdData.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});
export default DashboardPortfolioSlice.reducer;
