import AddMonteCarloTest from 'Components/Common/AddMonteCarloTest';
import React, { useState } from 'react';
import { Col, Row, Button } from 'reactstrap';

const Section = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Simulação Monte Carlo</h4>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <Button
                      type="button"
                      className="btn btn-soft-success"
                      onClick={openModal} // Abre o modal ao clicar no botão
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>
                      Nova Simulação Monte Carlo
                    </Button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>

      {/* Modal para criar nova simulação de Monte Carlo */}
      <AddMonteCarloTest show={isModalOpen} handleClose={closeModal} />
    </React.Fragment>
  );
};

export default Section;
