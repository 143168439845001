import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../../Components/Common/ChartsDynamicColor';

const generateMonteCarloData = (numSimulations: any, numDataPoints: any) => {
  let series = [];

  for (let i = 0; i < numSimulations; i++) {
    let data = [];
    let value = 0;

    for (let j = 0; j < numDataPoints; j++) {
      value += Math.random() * 100 - 40; // Simula um passo aleatório
      data.push(value);
    }

    series.push({
      name: `Simulação ${i + 1}`,
      data: data,
    });
  }

  return series;
};

const MonteCarloChart = ({ numSimulations = 250, numDataPoints = 100, dataColors }: { numSimulations?: number; numDataPoints?: number; dataColors: any }) => {
  const monteCarloSeries = generateMonteCarloData(numSimulations, numDataPoints);
  const linechartcustomerColors = getChartColorsArray(dataColors);

  const options = {
    chart: {
      height: 370,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    xaxis: {
      categories: Array.from({ length: numDataPoints }, (_, i) => `Dia ${i + 1}`),
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        formatter: function (value: string, index: number) {
          return index % 10 === 0 ? value : '';
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return value.toFixed(2);
        },
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 10,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: false,
    },
    colors: linechartcustomerColors,
    tooltip: {
      enabled: false, // Desabilita o tooltip
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart options={options} series={monteCarloSeries} type="line" height={370} />
    </React.Fragment>
  );
};

export default MonteCarloChart;
