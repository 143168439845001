import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BotList from './dashboard-bot-list';
import Revenue from './Revenue';
import BotPortfolioList from './dashboard-bot-portfolio';
import Section from './Section';
import { getCapitalCurveData, getRevenueChartsData, getSummaryPortfolioByDayData } from 'slices/thunks';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

export const formatDate = (date: Date) => {
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
};

export const getFirstDayOfMonth = (currentDate: Date) => {
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getNextBusinessDay = (currentDate: Date) => {
  const dayOfWeek = currentDate.getDay();
  let daysToAdd = 1;
  if (dayOfWeek === 5) {
    daysToAdd = 3;
  } else if (dayOfWeek === 6) {
    daysToAdd = 2;
  }
  return new Date(currentDate.getTime() + daysToAdd * 86400000);
};

export const initSelectedDates = () => {
  const storedDates = sessionStorage.getItem('selectedDates');
  if (storedDates) {
    return JSON.parse(storedDates);
  }
  const today = new Date();
  const firstDayOfMonth = getFirstDayOfMonth(today);
  const nextBusinessDay = getNextBusinessDay(today);
  const initialDates = [formatDate(firstDayOfMonth), formatDate(nextBusinessDay)];
  sessionStorage.setItem('selectedDates', JSON.stringify(initialDates));
  return initialDates;
};

const Dashboard = () => {
  document.title = 'Dashboard | QuantARM';
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };
  const selectDashboardData = createSelector(
    (state: any) => state.Dashboard.revenueData,
    (state: any) => state.Dashboard.capitalCurveData,
    (revenueData, capitalCurveData) => ({
      revenueData: revenueData ? revenueData.data : [],
      categories: revenueData ? revenueData.categories : [],
      capitalCurveData: capitalCurveData ? capitalCurveData : [],
    }),
  );

  const { revenueData, capitalCurveData } = useSelector(selectDashboardData);

  const portfolioListData = useSelector((state: any) => state.Dashboard.portfolioListData);

  const selectedAccounts: any = useSelector((state: any) => state.Layout.selectedAccounts || []);

  useEffect(() => {
    dispatch(getRevenueChartsData({ period: 'daily', selectedDates, selectedAccount: selectedAccounts }));

    dispatch(getSummaryPortfolioByDayData({ selectedDates, selectedAccount: selectedAccounts }));

    dispatch(getCapitalCurveData({ period: 'daily', selectedDates, selectedAccount: selectedAccounts }));
  }, [dispatch, selectedDates, selectedAccounts]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} />
                <Row>
                  <Col xl={12}>
                    <Revenue selectedDates={selectedDates} selectedCurveCapitals={capitalCurveData} />
                  </Col>
                </Row>
                <Row>
                  <BotPortfolioList selectedRevenueData={portfolioListData} />
                </Row>
                <Row>
                  <BotList selectedRevenueData={revenueData} />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
