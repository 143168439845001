//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import { postFakeRegister, postJwtRegister } from '../../../helpers/fakebackend_helper';

// action
import { registerUserSuccessful, registerUserFailed, resetRegisterFlagChange } from './reducer';

// Is user register successfull then direct plot user in redux.
export const registerUser = (user: any) => async (dispatch: any) => {
  try {
    let response;
    // initialize relavant method of both Auth
    const fireBaseBackend: any = getFirebaseBackend();
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      response = await fireBaseBackend.registerUser(user.email, user.password);
      if (response && response.uid) {
        // Check if the response has a uid which means a user was created
        dispatch(registerUserSuccessful(response)); // You can dispatch the whole user object or any specific part of it
      } else {
        dispatch(registerUserFailed(response)); // Generic error message, can be customized
      }
      // yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      response = postJwtRegister('/post-jwt-register', user);
      // yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_API_URL) {
      response = postFakeRegister(user);

      const data: any = await response;

      if (data.message === 'success') {
        dispatch(registerUserSuccessful(data));
      } else {
        dispatch(registerUserFailed(data));
      }
    }
  } catch (error: any) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
