import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';

const BotTab = ({ magicList, handleSwitchChange }: { magicList: any[]; handleSwitchChange: (key: number, status: boolean) => void }) => {
  return (
    <Card>
      <CardHeader className="border-1 align-items-center d-flex">
        <h5 className="mb-0">Lista de Bots</h5>
      </CardHeader>
      <CardBody>
        <Row>
          {magicList.map((item, key) => (
            <Col xxl={3} sm={6} key={key}>
              <Card className="profile-project-card shadow-none profile-project-warning">
                <CardBody className="p-4">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      <h5 className="fs-14 text-truncate mb-2">
                        MagicNumber: <span className="text-success">{item.magic}</span>
                      </h5>
                      <p className="text-muted text-truncate mb-2">
                        AccountNumber: <span className="fw-semibold text-body">{item.login_id}</span>
                      </p>
                      <p className="text-muted text-truncate mb-2">
                        Quantidade de Operações: <span className="fw-semibold text-body">100</span>
                      </p>
                      <p className="text-muted text-truncate mb-2">
                        Data de Início: <span className="fw-semibold text-body">01/01/2024</span>
                      </p>
                    </div>
                    <div className="form-check form-switch align-self-start">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`SwitchCheck${key}`}
                        defaultChecked={item.status}
                        onChange={(e) => handleSwitchChange(key, e.target.checked)}
                      />
                      <Label className="form-check-label ms-2" htmlFor={`SwitchCheck${key}`}></Label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default BotTab;
