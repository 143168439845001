import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication
import LoginReducer from './auth/login/reducer';
import AccountReducer from './auth/register/reducer';
import ForgetPasswordReducer from './auth/forgetpwd/reducer';
import ProfileReducer from './auth/profile/reducer';
import SettingsReducer from './settings/reducer';
import DashboardBotReducer from './dashboardBot/reducer';
import DashboardPortfolioReducer from './dashboardPortfolio/reducer';
import DashboardReducer from './dashboard/reducer';
import MonteCarloReducer from './monteCarlo/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Dashboard: DashboardReducer,
  Settings: SettingsReducer,
  DashboardBot: DashboardBotReducer,
  DashboardPortfolio: DashboardPortfolioReducer,
  MonteCarlo: MonteCarloReducer,
});

export default rootReducer;
