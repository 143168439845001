import React, { useState } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

interface WidgetsProps {
  desvioPadraoDrawdown: number;
  medioDrawdown: number;
  doisDesvioPadraoDrawdown: number;
  tresDesvioPadraoDrawdown: number;
}

const Widgets: React.FC<WidgetsProps> = ({ desvioPadraoDrawdown, medioDrawdown, doisDesvioPadraoDrawdown, tresDesvioPadraoDrawdown }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const toggleModal = () => setModal(!modal);

  const widgetData = [
    {
      label: 'Desvio Padrão',
      counter: desvioPadraoDrawdown,
      prefix: 'R$',
      suffix: '',
      separator: ',',
      decimals: 2,
      icon: 'ri-line-chart-line',
      badgeClass: 'info',
      bgcolor: 'info',
      link: 'Ver detalhes',
      description: `Imagine que você está investindo em uma poupança. Em alguns meses, seu dinheiro rende um pouco mais, e em outros, um pouco menos. O desvio padrão é como uma régua que mede o quanto esses rendimentos variam em relação à média. 
                    Por exemplo, se você geralmente ganha R$ 100 por mês, mas às vezes ganha R$ 90 ou R$ 110, o desvio padrão indica quão "normal" essas variações são. Um desvio padrão maior significa que os rendimentos estão mais "bagunçados" e podem variar bastante, enquanto um desvio padrão menor significa que os rendimentos são mais estáveis.`,
    },
    {
      label: 'Drawdown Médio + 1 Desvio Padrão',
      counter: medioDrawdown + desvioPadraoDrawdown,
      prefix: '- R$',
      suffix: '',
      separator: ',',
      decimals: 2,
      icon: 'ri-bar-chart-line',
      badgeClass: 'warning',
      bgcolor: 'warning',
      link: 'Ver detalhes',
      description: `Agora, imagine que durante um período de investimento, o valor do seu investimento caiu. Esse tipo de queda é o que chamamos de drawdown. O drawdown médio é como a média dessas quedas. 
                    Se o drawdown médio é R$ 100, significa que, em média, você perde R$ 100 em um período ruim. Ao adicionar 1 desvio padrão a isso, você está olhando para uma situação em que a queda é maior do que o usual, mas ainda dentro de uma margem razoável. 
                    Isso seria como perder R$ 120 em um mês ruim, onde as coisas não estão muito fora do controle, mas é mais do que você geralmente perde.`,
    },
    {
      label: 'Drawdown Médio + 2 Desvios Padrões',
      counter: medioDrawdown + doisDesvioPadraoDrawdown,
      prefix: '- R$',
      suffix: '',
      separator: ',',
      decimals: 2,
      icon: 'ri-bar-chart-line',
      badgeClass: 'danger',
      bgcolor: 'danger',
      link: 'Ver detalhes',
      description: `Adicionar 2 desvios padrões ao drawdown médio é como prever uma situação bem mais difícil, onde o mercado ou o investimento tem uma performance muito pior do que o normal. 
                    Se o drawdown médio é R$ 100, adicionar 2 desvios padrões pode significar uma perda de R$ 140, por exemplo. 
                    Isso mostra um cenário em que você poderia perder mais do que o esperado em um mês especialmente ruim, mas ainda dentro dos limites do que poderia acontecer.`,
    },
    {
      label: 'Drawdown Médio + 3 Desvios Padrões',
      counter: medioDrawdown + tresDesvioPadraoDrawdown,
      prefix: '- R$',
      suffix: '',
      separator: ',',
      decimals: 2,
      icon: 'ri-bar-chart-line',
      badgeClass: 'danger',
      bgcolor: 'danger',
      link: 'Ver detalhes',
      description: `Este é o pior cenário possível, onde as coisas realmente saem dos trilhos. 
                    Adicionar 3 desvios padrões ao drawdown médio é como se preparar para uma perda extrema, algo que quase nunca acontece, mas é bom estar ciente de que pode ocorrer. 
                    Se o drawdown médio é R$ 100, adicionar 3 desvios padrões poderia indicar uma perda de R$ 160 ou mais. 
                    É como imaginar que, em um mês de muito azar, você perderia uma quantia significativa, mais do que o habitual, em um dos piores cenários possíveis.`,
    },
  ];

  const handleDetailsClick = (item: any) => {
    setSelectedItem(item);
    toggleModal();
  };

  return (
    <React.Fragment>
      {widgetData.map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    <i className={'fs-13 align-middle ' + item.icon}></i>
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className={`fs-22 fw-semibold ff-secondary mb-4 bg-soft-${item.badgeClass} text-${item.badgeClass} rounded`}>
                    <span className="counter-value">
                      <CountUp start={0} prefix={item.prefix} suffix={item.suffix} separator={item.separator} end={item.counter} decimals={item.decimals} duration={4} />
                    </span>
                  </h4>
                  <Link to="#" className="text-decoration-underline" onClick={() => handleDetailsClick(item)}>
                    {item.link}
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={`avatar-title bg-soft-${item.bgcolor} text-${item.bgcolor} rounded fs-3`}>
                    <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}

      {/* Modal para detalhes */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{selectedItem?.label}</ModalHeader>
        <ModalBody>
          <p>{selectedItem?.description}</p>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Widgets;
