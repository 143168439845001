import { createSlice } from '@reduxjs/toolkit';
import { getCapitalCurveData, getRevenueChartsData, getSummaryPortfolioByDayData } from '../dashboard/thunk';
export const initialState: any = {
  revenueData: [],
  capitalCurveData: [],
  portfolioListData: [],
  error: {},
};
const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRevenueChartsData.fulfilled, (state, action) => {
      state.revenueData = action.payload;
    });
    builder.addCase(getRevenueChartsData.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(getCapitalCurveData.fulfilled, (state, action) => {
      state.capitalCurveData = action.payload;
    });
    builder.addCase(getCapitalCurveData.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(getSummaryPortfolioByDayData.fulfilled, (state, action) => {
      state.portfolioListData = action.payload;
    });
    builder.addCase(getSummaryPortfolioByDayData.rejected, (state, action) => {
      state.error = {};
    });
  },
});
export default DashboardSlice.reducer;
