import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../Components/Common/ChartsDynamicColor';

const formatCategories = (categories: any[]) => {
  return categories.map((category) => category.split(' ')[0]);
};

const RevenueCharts = ({ dataColors, series, categories }: any) => {
  if (!dataColors || dataColors.length < 1 || !series || series.length < 1 || !categories || categories.length < 1) {
    return null;
  }

  const formattedCategories = formatCategories(categories);
  var linechartcustomerColors = getChartColorsArray(dataColors);

  const formatValue = (y: any) => {
    if (typeof y !== 'undefined') {
      const formattedValue = y
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .replace('.', ',');
      return `R$ ${formattedValue}`;
    }
    return y;
  };

  const getColorBasedOnValue = (value: number) => {
    return value >= 0 ? 'green' : 'red';
  };

  const tooltipFormatter = (value: any, { seriesIndex, dataPointIndex }: any) => {
    // Acessar os valores diário e acumulado para o ponto de dados atual
    const accumulatedValue = series[seriesIndex].data[dataPointIndex];
    const dailyValue = series[seriesIndex].dataAccumulated[dataPointIndex];

    // Formatar ambos os valores
    const dailyFormatted = formatValue(dailyValue);
    const accumulatedFormatted = formatValue(accumulatedValue);

    // Obter a cor com base no valor
    const dailyColor = getColorBasedOnValue(dailyValue);

    // Construir e retornar o HTML do tooltip com cores condicionais
    // Construir e retornar o HTML do tooltip com a cor apenas para o valor formatado
    return `
      <div><strong>Diário:</strong> <span style="color: ${dailyColor};">${dailyFormatted}</span></div>
      <div><strong>Acumulado:</strong> ${accumulatedFormatted}</div>
    `;
  };

  var options: any = {
    chart: {
      height: 370,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 0, 0, 0],
      width: [1, 1, 1, 0, 0],
    },
    fill: {
      opacity: [2, 2, 2, 0.5, 0.5],
    },
    markers: {
      size: [0, 0, 0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: formattedCategories,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 10,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        barHeight: '70%',
      },
    },
    colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: {
        formatter: tooltipFormatter,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart dir="ltr" options={options} series={series} type="line" height="370" className="apex-charts" />
    </React.Fragment>
  );
};

const UsersByDeviceCharts = ({ dataColors, series }: any) => {
  var dountchartUserDeviceColors = getChartColorsArray(dataColors);
  const options: any = {
    labels: ['Desktop', 'Mobile', 'Tablet'],
    chart: {
      type: 'donut',
      height: 219,
    },
    plotOptions: {
      pie: {
        size: 100,
        donut: {
          size: '76%',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: 0,
      markers: {
        width: 20,
        height: 6,
        radius: 2,
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
    stroke: {
      width: 0,
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return value + 'k Users';
        },
      },
      tickAmount: 4,
      min: 0,
    },
    colors: dountchartUserDeviceColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr" options={options} series={series} type="donut" height="219" className="apex-charts" key={Date.now()} />
    </React.Fragment>
  );
};

const TopArtworkChart = ({ seriesData, chartsColor }: any) => {
  const topartworkColors = [chartsColor];
  var options: any = {
    chart: {
      width: 200,
      height: 40,
      type: 'line',
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2.3,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return '';
          },
        },
      },
      marker: {
        show: false,
      },
    },
    colors: topartworkColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr" options={options} series={[...seriesData]} type="line" height="30" width="200" />
    </React.Fragment>
  );
};
export { RevenueCharts, UsersByDeviceCharts, TopArtworkChart };
