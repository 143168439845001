import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getListMonteCarlo } from 'slices/thunks';
import Section from './Section';

const MonteCarlo = () => {
  document.title = 'Lista de Simulações';

  const dispatch: any = useDispatch();
  const [simulacoes, setSimulacoes] = useState<any[]>([]);

  useEffect(() => {
    const fetchSimulacoes = async () => {
      try {
        const response = await dispatch(getListMonteCarlo()).unwrap();
        setSimulacoes(response);
      } catch (error) {
        console.error('Erro ao buscar simulações:', error);
      }
    };

    fetchSimulacoes();
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section />
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome da Simulação</th>
                      <th>Data Inicio Backtest</th>
                      <th>Data Fim Backtest</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {simulacoes.map((simulacao, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{simulacao.nome_simulacao}</td>
                        <td>{simulacao.data_primeira_operacao}</td>
                        <td>{simulacao.data_ultima_operacao}</td>
                        <td>
                          <Link to={`detalhes-monte-carlo/${simulacao.magic_number}`}>Ver Detalhes</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MonteCarlo;
