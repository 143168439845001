import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import RevenueBot from './Revenue-Bot';
import { useSelector } from 'react-redux';
import { getCapitalCurveDataByMagicNumberData } from 'slices/thunks';
import Section from '../Section';
import { useParams } from 'react-router-dom';
import { PaginationTable } from './ReactTable';
import { initSelectedDates } from '..';

const DashboardBot = () => {
  const { id } = useParams();
  document.title = `Dashboard | QuantARM`;
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());

  const [paginationData, setPaginationData] = useState([]);

  const selectDashboardBotData = createSelector(
    (state: any) => state.DashboardBot.historyBotCurveData,
    (historyBotCurveData: any) => ({
      historyBotCurveData: historyBotCurveData ? historyBotCurveData : [],
    }),
  );

  const { historyBotCurveData } = useSelector(selectDashboardBotData);

  const selectedAccounts: any = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  useEffect(() => {
    dispatch(getCapitalCurveDataByMagicNumberData({ period: 'daily', selectedDates, selectedAccount: selectedAccounts, roboId: Number(id) }));
  }, [dispatch, selectedDates, selectedAccounts, id]);

  useEffect(() => {
    if (historyBotCurveData && historyBotCurveData.length > 0) {
      const formattedData = historyBotCurveData[0].data
        .map((value: number, index: number) => ({
          magicNumber: id,
          date: historyBotCurveData[0].periods[index],
          valor: `R$ ${value.toFixed(2)}`,
          status: value > 0 ? 'Gain' : value < 0 ? 'Loss' : 'Zero',
        }))
        .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

      setPaginationData(formattedData);
    }
  }, [historyBotCurveData, id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} />
                <Row>
                  <Col xl={12}>
                    <RevenueBot selectedDates={selectedDates} selectDashboardBotData={historyBotCurveData} robotId={Number(id)} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">Operações diária</h5>
                      </CardHeader>
                      <CardBody>
                        <PaginationTable data={paginationData} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardBot;
