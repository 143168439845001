import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { TopArtworkChart } from './Dashboard';

type BotListProps = {
  selectedRevenueData: any;
};

const BotList = ({ selectedRevenueData }: BotListProps) => {
  const processData = (data: any[]) => {
    if (!data) return [];
    return data
      .filter((robot) => !isNaN(parseFloat(robot.name)))
      .map((robot) => {
        const accumulatedData = robot.data.reduce((acc: number[], curr: number) => {
          const lastSum = acc.length > 0 ? acc[acc.length - 1] : 0;
          acc.push(lastSum + curr);
          return acc;
        }, []);
        const totalProfit = accumulatedData[accumulatedData.length - 1];
        const maxGain = Math.max(...robot.data);
        const maxLoss = Math.min(...robot.data);
        const wins = robot.data.filter((value: any) => value > 0).length;

        let peak = 0;
        let largestDrawdown = 0;
        let currentDrawdown = 0;

        accumulatedData.forEach((value: number) => {
          if (value > peak) {
            peak = value;
            currentDrawdown = 0;
          }
          const drawdown = peak - value;
          if (drawdown > largestDrawdown) largestDrawdown = drawdown;
          currentDrawdown = drawdown;
        });

        return {
          roboId: robot.name,
          chartData: accumulatedData,
          totalProfit,
          maxGain,
          maxLoss,
          wins,
          total: robot.data.length,
          largestDrawdown,
          currentDrawdown,
        };
      });
  };

  const formattedData = processData(selectedRevenueData);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Lista de Robos</h4>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              {formattedData.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '40px', color: 'rgba(200, 200, 200, 0.5)' }}>
                  <p style={{ fontSize: '20px' }}>Nenhum registro encontrado para o filtro aplicado.</p>
                </div>
              ) : (
                <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                  <thead className="text-muted table-light">
                    <tr>
                      <th scope="col">Robo Id</th>
                      <th scope="col">Gráfico</th>
                      <th scope="col">Lucro atual</th>
                      <th scope="col">Maior ganho diário</th>
                      <th scope="col">Maior perda diário</th>
                      <th scope="col">Maior Drawdown</th>
                      <th scope="col">Drawdown Atual</th>
                      <th scope="col">Acertos/total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(formattedData || []).map((item: any, key: any) => (
                      <tr key={key} className="border-bottom border-bottom-dashed py-2">
                        <td>
                          <Link to={`/dashboard-bot/${item.roboId}`} className="fw-medium link-primary">
                            {item.roboId}
                          </Link>
                        </td>
                        <td width={300}>
                          <TopArtworkChart seriesData={[{ name: item.roboId, data: item.chartData }]} chartsColor="#00ff00" />
                        </td>
                        <td>
                          <span className={item.totalProfit < 0 ? 'text-danger' : 'text-success'}>R$ {item.totalProfit.toFixed(2)}</span>
                        </td>
                        <td>
                          <span className={item.maxGain <= 0 ? 'text-muted' : 'text-success'}>R$ {item.maxGain > 0 ? item.maxGain.toFixed(2) : '0.00'}</span>
                        </td>

                        <td>{item && item.maxLoss !== undefined ? <span className={'text-danger'}>R$ {item.maxLoss.toFixed(2)}</span> : null}</td>
                        <td>{item && item.largestDrawdown !== undefined ? <span className={'text-danger'}>R$ {item.largestDrawdown.toFixed(2)}</span> : null}</td>
                        <td>{item && item.currentDrawdown !== undefined ? <span className={'text-info'}>R$ {item.currentDrawdown.toFixed(2)}</span> : null}</td>

                        <td>
                          <span className={'text-danger'}>
                            {item.wins}/{item.total}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BotList;
