import React from 'react';
import { Card, CardHeader, Col, CardBody } from 'reactstrap';
import { TopArtworkChart } from './Dashboard';
import { Link } from 'react-router-dom';

type BotPortfolioListProps = {
  selectedRevenueData: {
    id: number;
    name: string;
    magics: string;
    accumulatedProfits: number[];
    totalProfit: number;
    maxGain: number;
    maxLoss: number;
    maxDrawdown: number;
    drawdownAtual: number;
  }[];
};

const BotPortfolioList = ({ selectedRevenueData = [] }: BotPortfolioListProps) => {
  const formatValue = (value: number, textColor: string) => {
    const formattedValue = `R$ ${value.toFixed(2)}`;

    if (textColor === '') {
      if (value > 0) {
        textColor = 'text-success';
      } else if (value < 0) {
        textColor = 'text-danger';
      } else {
        textColor = 'text-warning';
      }
    }

    return <span className={textColor}>{formattedValue}</span>;
  };
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Lista de portfólios</h4>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                <thead className="table-light text-muted">
                  <tr>
                    <th scope="col">Portfolio</th>
                    <th scope="col">Gráfico</th>
                    <th scope="col">Lucro atual</th>
                    <th scope="col">Maior ganho diário</th>
                    <th scope="col">Maior Perda diária</th>
                    <th scope="col">Maior Drawndown</th>
                    <th scope="col">Drawndown atual</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  {selectedRevenueData &&
                    selectedRevenueData.map((item, index) => (
                      <tr key={index} className="border-bottom border-bottom-dashed py-2">
                        <td width={320}>
                          <Link to={`/dashboard-portfolio/${item.id}`} className="fw-medium link-primary">
                            <h4 className="text-truncate fs-14 fs-medium mb-0">
                              <i className="ri-stop-fill align-middle fs-18 text-primary me-2"></i>
                              {item.name}
                            </h4>
                            <p className="text-muted mb-0" style={{ fontSize: '12px' }}>
                              ({item.magics})
                            </p>
                          </Link>
                        </td>
                        <td width={300}>
                          <TopArtworkChart seriesData={[{ name: item.magics, data: item.accumulatedProfits }]} chartsColor="#00ff00" />
                        </td>
                        <td>{item && item.totalProfit !== undefined ? formatValue(item.totalProfit, '') : null}</td>
                        <td>{item && item.maxGain !== undefined ? formatValue(item.maxGain, 'text-success') : null}</td>
                        <td>{item && item.maxLoss !== undefined ? formatValue(item.maxLoss, item.maxLoss < 0 ? 'text-danger' : 'text-warning') : null}</td>
                        <td>{item && item.maxDrawdown !== undefined ? formatValue(item.maxDrawdown, item.maxDrawdown > 0 ? 'text-danger' : 'text-warning') : null}</td>
                        <td>{item && item.drawdownAtual !== undefined ? formatValue(item.drawdownAtual, 'text-info') : null}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BotPortfolioList;
