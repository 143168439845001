import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, Label } from 'reactstrap';
import Select from 'react-select';
import classnames from 'classnames';

//Images
//import profileBg from 'assets/images/profile-bg.jpg';
import profileQuantArm from 'assets/images/Logo-QuantARM-2.png';
import avatar1 from 'assets/images/Logo-QuantARM-4.png';

import { createPortfolioData, getListMagicData, getListPortfolioData, updateMagicData, updatePortfolioData } from 'slices/settings/thunk';
import { useSelector } from 'react-redux';
import PortfolioTab from './settings-portfolio-tab';
import BotTab from './settings-bot-tab';
import { getUidFromSessionStorage } from 'slices/thunks';

const Settings = () => {
  document.title = 'Settings | QuantARM';
  const dispatch: any = useDispatch();

  const [activeTab, setActiveTab] = useState('1');
  const [showModal, setShowModal] = useState(false);
  const [portfolioName, setPortfolioName] = useState('');
  const [portfolioDescription, setPortfolioDescription] = useState('');
  const magicList = useSelector((state: any) => state.Settings.magicList || []);
  const portfolioList = useSelector((state: any) => state.Settings.portfolioList || []);
  const [selectedRobots, setSelectedRobots] = useState<any[]>([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editPortfolioId, setEditPortfolioId] = useState<number | null>(null);
  const selectedAccounts = useSelector((state: any) => state.Layout.selectedAccounts || []);

  useEffect(() => {
    const transformed = magicList.map((robot: any) => ({
      value: robot.magic,
      label: `Magic #${robot.magic}`,
    }));
    setSelectedRobots(transformed);
  }, [magicList]);

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedMulti(selectedOptions || []);
  };

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      dispatch(getListMagicData(selectedAccounts[0]));
      dispatch(getListPortfolioData(selectedAccounts[0]));
    }
  }, [selectedAccounts, dispatch]);

  const handleSwitchChange = (key: number, status: boolean) => {
    dispatch(updateMagicData({ magic: magicList[key].magic, status, selectedAccount: selectedAccounts[0] }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedMulti.length === 0) {
      alert('Por favor, selecione ao menos um magic.');
      return;
    }

    const portfolioData = {
      name: portfolioName,
      descricao: portfolioDescription,
      userHash: getUidFromSessionStorage(),
      login: selectedAccounts[0],
      portfolioMagics: selectedMulti.map((robot: any) => ({ magic: robot.value })),
    };
    try {
      if (isEditMode && editPortfolioId) {
        dispatch(updatePortfolioData({ id: editPortfolioId, data: portfolioData })).then(() => {
          dispatch(getListPortfolioData(selectedAccounts[0]));
          setShowModal(false);
        });
      } else {
        await dispatch(createPortfolioData({ data: portfolioData })).then(() => {
          dispatch(getListPortfolioData(selectedAccounts[0]));
          setShowModal(false);
        });
      }
    } catch (error) {
      console.error('Error updating or adding portfolio', error);
    }
  };

  const toggleModal = (event?: React.MouseEvent<HTMLButtonElement>, portfolio?: any) => {
    event?.preventDefault();
    if (portfolio) {
      setIsEditMode(true);
      setPortfolioName(portfolio.name);
      setPortfolioDescription(portfolio.descricao);
      setEditPortfolioId(portfolio.id);
      setSelectedMulti(
        portfolio.portfolioMagics.map((magic: any) => ({
          label: `Magic #${magic.magic}`,
          value: magic.magic,
        })),
      );
    } else {
      setIsEditMode(false);
      setPortfolioName('');
      setPortfolioDescription('');
      setEditPortfolioId(null);
      setSelectedMulti([]);
    }
    setShowModal((prev) => !prev);
  };

  const customStyles = {
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        backgroundColor: '#3762ea',
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      backgroundColor: '#405189',
      color: 'white',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#405189',
      ':hover': {
        backgroundColor: '#405189',
        color: 'white',
      },
    }),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img src={profileQuantArm} alt="" className="profile-wid-img" />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img src={avatar1} alt="user-img" className="img-thumbnail rounded-circle" />
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">Configuração</h3>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2">
                      <i className="ri-settings-3-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Visualização de todos os bots e portfólios disponíveis.
                    </div>
                  </div>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2">
                      <i className="ri-settings-3-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Configuração para ativar/desativar os bots.
                    </div>
                  </div>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2">
                      <i className="ri-settings-3-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Criação de portfólios.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                    <NavItem className="fs-14">
                      <NavLink
                        href="#bots"
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggleTab('1');
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Bots</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="fs-14">
                      <NavLink
                        href="#portfolios"
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggleTab('2');
                        }}
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Portfólio</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <BotTab magicList={magicList} handleSwitchChange={handleSwitchChange} />
                  </TabPane>
                  <TabPane tabId="2">
                    <PortfolioTab toggleModal={toggleModal} portfolioList={portfolioList} />
                  </TabPane>
                  <TabPane tabId="3"></TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader className="bg-light p-3" toggle={toggleModal}>
          {isEditMode ? 'Edit Portfolio' : 'Add Portfolio'}
        </ModalHeader>
        <Form className="tablelist-form" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3">
              <Label htmlFor="company-field" className="form-label">
                Nome do portfolio
              </Label>
              <Input type="text" id="portfolio-name" className="form-control" placeholder="Digite o nome do portfolio" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} />
            </div>
            <div className="mb-3">
              <Label htmlFor="company-field" className="form-label">
                Descrição do portfolio
              </Label>
              <div className="input-group">
                <textarea
                  className="form-control"
                  id="portfolio-description"
                  rows={3}
                  placeholder="Digite uma breve descrição do portfólio"
                  value={portfolioDescription}
                  onChange={(e) => setPortfolioDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="mb-3">
              <Label htmlFor="company-field" className="form-label">
                Selecione os bots
              </Label>
              <Select id="robots-select" isMulti options={selectedRobots} styles={customStyles} classNamePrefix="js-example-disabled-multi mb-0" value={selectedMulti} onChange={handleSelectChange} />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggleModal}>
                Close
              </Button>
              <Button type="submit" className="btn btn-success">
                {isEditMode ? 'Update' : 'Add'}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Settings;
