import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, UncontrolledDropdown } from 'reactstrap';

//import images
import logoSm from '../assets/images/logo-sm.png';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/Logo_Horizontal_Fundo_Branco.png';
//import LogoArm from '../assets/images/Logo-QuantARM.png';

//import Components
import SearchOption from '../Components/Common/SearchOption';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';

import { UserAccount, changeSidebarVisibility, getAccountsByUidData } from '../slices/thunks';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { setSelectedAccounts } from 'slices/layouts/reducer';

const selectLayoutData = createSelector(
  (state) => state.Layout,
  (layout) => ({
    sidebarVisibilitytype: layout.sidebarVisibilitytype,
    selectedAccounts: layout.selectedAccounts || [],
    accounts: layout.userAccount || [],
  }),
);

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }: any) => {
  const dispatch: any = useDispatch();

  const { sidebarVisibilitytype, selectedAccounts, accounts } = useSelector(selectLayoutData);
  const [search, setSearch] = useState<boolean>(false);
  const accountList = accounts && accounts.length > 0 ? accounts.map((account: UserAccount) => ({ name: account.login_id.toString() })) : [];

  const toogleSearch = () => {
    setSearch(!search);
  };

  useEffect(() => {
    dispatch(getAccountsByUidData());
  }, [dispatch]);

  useEffect(() => {
    const localData = localStorage.getItem('selectedAccount');
    let accountsFromStorage = localData ? JSON.parse(localData) : [];

    if (accountsFromStorage.length === 0 && accounts.length > 0) {
      // Se não houver contas selecionadas e houver contas disponíveis, seleciona a primeira.
      accountsFromStorage = [accounts[0].login_id.toString()];
      localStorage.setItem('selectedAccount', JSON.stringify(accountsFromStorage));
    }

    dispatch(setSelectedAccounts(accountsFromStorage));
  }, [dispatch, accounts]); // Adicione 'accounts' como dependência para garantir que ele seja reavaliado quando as contas forem carregadas.

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector('.hamburger-icon') as HTMLElement;
    dispatch(changeSidebarVisibility('show'));

    if (windowSize > 767) humberIcon.classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu');
    }

    //For collapse vertical and semibox menu
    if (sidebarVisibilitytype === 'show' && (document.documentElement.getAttribute('data-layout') === 'vertical' || document.documentElement.getAttribute('data-layout') === 'semibox')) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
    }
  };

  const handleItemClick = (accountName: string) => {
    const updatedAccounts = selectedAccounts[0] === accountName ? selectedAccounts : [accountName];
    localStorage.setItem('selectedAccount', JSON.stringify(updatedAccounts));
    dispatch(setSelectedAccounts(updatedAccounts));
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="25" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="25" />
                  </span>
                </Link>
              </div>

              <button onClick={toogleMenuBtn} type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <SearchOption />
            </div>

            <div className="d-flex align-items-center">
              <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>
              <div className="col-auto">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle tag="a" className="text-reset" role="button">
                    <span className="fw-semibold text-uppercase fs-12">Selecione a conta: </span>
                    <span className="text-muted">
                      {selectedAccounts.length > 0 ? selectedAccounts[0] : 'Todos'}
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {accountList.map((robot: any) => (
                      <DropdownItem key={robot.name} onClick={() => handleItemClick(robot.name)}>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            name="accountSelection"
                            id={`inlineRadio${robot.name}`}
                            value={robot.name}
                            checked={selectedAccounts.includes(robot.name)}
                            //onChange={handleRadioChange} // Atualizado para usar a função correta
                          />
                          <Label className="form-check-label" htmlFor={`inlineRadio${robot.name}`}>
                            {robot.name}
                          </Label>
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* LanguageDropdown */}
              <LanguageDropdown />
              {/* FullScreenDropdown */}
              <FullScreenDropdown />
              {/* Dark/Light Mode set */}
              <LightDark layoutMode={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} />
              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
