import { createAsyncThunk } from '@reduxjs/toolkit';
import { PortfolioData, createPortfolio, getListMagic, getListPortfolio, updateMagic, updatePortfolio } from 'helpers/fakebackend_helper';
import { getUidFromSessionStorage } from 'slices/thunks';

const account = localStorage.getItem('selectedAccount');

export const getListMagicData = createAsyncThunk('settings/getMagic', async ({ selectedAccount }: { selectedAccount: number }) => {
  if (!account) {
    throw new Error('selectedAccount não encontrado no localStorage');
  }
  const response = await getListMagic(selectedAccount ? selectedAccount : JSON.parse(account)[0]);
  return response;
});

export const updateMagicData = createAsyncThunk('settings/updateMagic', async ({ magic, status, selectedAccount }: { magic: number; status: boolean; selectedAccount: number }) => {
  if (!account) {
    throw new Error('selectedAccount não encontrado no localStorage');
  }
  const response = await updateMagic(selectedAccount ? selectedAccount : JSON.parse(account)[0], magic, status);
  return response;
});

export const createPortfolioData = createAsyncThunk('settings/createPortfolio', async ({ data }: { data: PortfolioData }) => {
  return await createPortfolio(data);
});

export const updatePortfolioData = createAsyncThunk('settings/updatePortfolio', async ({ id, data }: { id: number; data: PortfolioData }) => {
  data.userHash = getUidFromSessionStorage();
  return await updatePortfolio(id, data);
});

export const getListPortfolioData = createAsyncThunk('settings/getPortfolio', async ({ selectedAccount }: { selectedAccount: number }) => {
  if (!account) {
    throw new Error('selectedAccount não encontrado no localStorage');
  }
  const response = await getListPortfolio(selectedAccount ? selectedAccount : JSON.parse(account)[0]);
  return response;
});
