import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCapitalCurveDataByMagicNumber } from '../../helpers/fakebackend_helper';
import { getUidFromSessionStorage } from 'slices/thunks';

const formatDate = (dates: string[]) => {
  for (let i = 0; i < dates.length; i++) {
    if (dates[i].includes('/')) {
      const dateArray = dates[i].split('/');
      dates[i] = `${dateArray[2]}-${dateArray[1].padStart(2, '0')}-${dateArray[0].padStart(2, '0')}`;
    } else if (dates[i].includes('T')) {
      dates[i] = dates[i].split('T')[0];
    }
  }
  return dates;
};

export const getCapitalCurveDataByMagicNumberData = createAsyncThunk(
  'dashboardBot/getCapitalCurveDataByMagicNumberData',
  async ({ period, selectedDates, selectedAccount, roboId }: { period: string; selectedDates: string[]; selectedAccount: string[]; roboId: number }) => {
    if (selectedAccount.length === 0) return Promise.resolve({});
    try {
      const uid = getUidFromSessionStorage();
      return await getCapitalCurveDataByMagicNumber(uid, Number(selectedAccount[0]), formatDate(selectedDates), roboId);
    } catch (error) {
      throw error;
    }
  },
);
