import { createSlice } from '@reduxjs/toolkit';
import { getCapitalCurveDataByMagicNumberData } from './thunk';
export const initialState: any = {
  historyBotCurveData: [],
  error: {},
};
const DashboardBotSlice = createSlice({
  name: 'DashboardBot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCapitalCurveDataByMagicNumberData.fulfilled, (state, action) => {
      state.historyBotCurveData = action.payload;
    });
    builder.addCase(getCapitalCurveDataByMagicNumberData.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});
export default DashboardBotSlice.reducer;
