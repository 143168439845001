import { Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import React from 'react';

type SectionProps = {
  selectedDates: string[];
  setSelectedDates: (dates: string[]) => void;
};

const getUserNameFromSessionStorage = () => {
  const authUserString = sessionStorage.getItem('authUser');
  if (authUserString) {
    const authUser = JSON.parse(authUserString);
    return authUser.providerData[0].displayName || 'Admin';
  } else {
    throw new Error('authUser não encontrado no sessionStorage');
  }
};

const Section = ({ selectedDates, setSelectedDates }: SectionProps) => {
  const handleDateChange = (selectedDates: Date[]) => {
    const dateStringArray = selectedDates.map((date) => date.toISOString());
    setSelectedDates(dateStringArray);
  };

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Bem vindo, {getUserNameFromSessionStorage()}!</h4>
            </div>

            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        options={{
                          mode: 'range',
                          dateFormat: 'd/m/Y',
                          defaultDate: selectedDates,
                          onChange: handleDateChange,
                          locale: Portuguese,
                        }}
                      />
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
