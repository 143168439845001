import { createSlice } from '@reduxjs/toolkit';
import { createPortfolioData, getListMagicData, getListPortfolioData, updateMagicData, updatePortfolioData } from './thunk';
export const initialState: any = {
  magicList: [],
  portfolioList: [],
  error: {},
};
const SettingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListMagicData.fulfilled, (state, action: any) => {
        state.magicList = action.payload;
      })
      .addCase(getListMagicData.rejected, (state, action: any) => {
        state.error = action.payload.error || null;
      })
      .addCase(updateMagicData.fulfilled, (state, action: any) => {})
      .addCase(updateMagicData.rejected, (state, action: any) => {
        state.error = action.payload.error || null;
      })

      .addCase(createPortfolioData.fulfilled, (state, action: any) => {
        state.portfolioList = action.payload;
      })
      .addCase(createPortfolioData.rejected, (state, action: any) => {
        state.error = action.payload.error || null;
      })
      .addCase(updatePortfolioData.fulfilled, (state, action: any) => {
        state.portfolioList = action.payload;
      })
      .addCase(updatePortfolioData.rejected, (state, action: any) => {
        state.error = action.payload.error || null;
      })
      .addCase(getListPortfolioData.fulfilled, (state, action: any) => {
        state.portfolioList = action.payload;
      })
      .addCase(getListPortfolioData.rejected, (state, action: any) => {
        state.error = action.payload.error || null;
      });
  },
});
export default SettingsSlice.reducer;
