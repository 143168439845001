import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListMonteCarloByUserId, getListPortfolioMonteCarloByUserId, getMonteCarloByUserIdAndMagicNumber } from '../../helpers/fakebackend_helper';

export const getListMonteCarlo = createAsyncThunk('monteCarlo/getListMonteCarlo', async () => {
  try {
    return await getListMonteCarloByUserId();
  } catch (error) {
    throw error;
  }
});

export const getListPortfolioMonteCarlo = createAsyncThunk('monteCarlo/getListPortfolioMonteCarlo', async () => {
  try {
    return await getListPortfolioMonteCarloByUserId();
  } catch (error) {
    throw error;
  }
});

export const getMonteCarloByMagicNumber = createAsyncThunk('monteCarlo/getMonteCarloByMagicNumber', async ({ magic_number }: { magic_number: number }) => {
  try {
    return await getMonteCarloByUserIdAndMagicNumber(magic_number);
  } catch (error) {
    throw error;
  }
});
